import type { NextPage } from 'next';
import Head from 'next/head';


import Partners from '@source/components/Home/Partners';
import Main from '@source/components/Home/Main';
import SecondSection from '@source/components/Home/SecondSection';
import AnyDeviceSection from '@source/components/Home/AnyDeviceSection';
import Influencers from '@source/components/Home/Influencers';
import ChallengesCarouselHome from '@source/components/Home/ChallengesCarouselHome';
import { useEffect, useState } from 'react';
import BlogCarousel from '@source/components/BlogCarousel';

type ImageSize = {
  width: number;
  height: number;
};

const Home: NextPage = (): JSX.Element => {
  const [imageSize, setImageSize] = useState<ImageSize | undefined>();
  const [anyDeviceHeight, setAnyDeviceHeight] = useState<
    ImageSize | undefined
  >();

  useEffect(() => {
    if (window !== undefined) {
      const innerWidth = window.innerWidth;

      if (innerWidth < 768) {
        const imageHeight = innerWidth / 1.2;

        setImageSize({ height: imageHeight, width: innerWidth });
        return;
      }

      const windowWidth = innerWidth;

      const imageWidth = windowWidth / 2;

      const imageHeight = imageWidth / 1.2;

      const imageHeightAnyDevice = imageWidth / 1.6;
      setAnyDeviceHeight({ height: imageHeightAnyDevice, width: imageWidth });

      setImageSize({ height: imageHeight, width: imageWidth });
    }
  }, []);

  return (
    <>
      <Head>
        <title>Riderize - Conectados pelo Ciclismo</title>
      </Head>

     
        <Main imageSize={imageSize} />
        <SecondSection imageSize={imageSize} />
        <ChallengesCarouselHome />
        <Influencers />
        <Partners />
        <AnyDeviceSection imageSize={anyDeviceHeight} />
        <BlogCarousel />
     
    </>
  );
};

export default Home;
